<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1">
          <img :src="logoImg" height="30" />
        </h2>
      </b-link>
      <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
          />
        </div>
      </b-col>
      <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
      <b-link class="language-switcher">
        <locale class="list-unstyled" />
      </b-link>
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
          >
            {{ $t('Welcome To Adx PLatform') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please sign-in to your account and start the adventure') }}
          </b-card-text>

          <b-alert
              variant="danger"
              show
              v-if="showError"
          >
            <h4 class="alert-heading">
              Oops!
            </h4>
            <div class="alert-body" v-if="errors">
              <span v-for="error in errors">{{error[0]}}</span>
            </div>
          </b-alert>
          <validation-observer ref="loginValidation">
            <b-form
                class="auth-login-form mt-2"
                @submit.prevent="submit"
            >
              <b-form-group
                  :label="$t('Username')"
                  label-for="login-username"
              >
                <b-form-input
                    id="login-email"
                    v-model="form.username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('Password') }}</label>
                  <b-link :to="{name:'login'}">
                    <small>{{ $t('Forgot Password') }}</small>
                  </b-link>
                </div>
                <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                      id="login-password"
                      type="password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="login-password"
                      placeholder="············"
                  />

                </b-input-group>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                    id="remember-me"
                    name="checkbox-1"
                >
                  {{ $t('Remember Me') }}
                </b-form-checkbox>
              </b-form-group>
              <b-button
                  type="submit"
                  variant="primary"
                  block
              >
                <template v-if="!isLoading">
                  {{ $t('Sign in') }}
                </template>
                <template v-if="isLoading">
                  {{ $t('Signing in') }}...
                  <b-spinner small />
                </template>
              </b-button>
            </b-form>
          </validation-observer>
          <!-- <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import jwt_decode from "jwt-decode";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {mapActions, mapGetters} from 'vuex'
import useJwt from '@/auth/useJwt'
import store from '@/store/'
import axios from '@axios'
import locale from "@/layouts/components/Locale.vue"

export default {
  components: {
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    locale
  },
  name:'Login',
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      logoImg: require('@/assets/images/logo/logo.png'),
      form:{
        username:'',
        password: ''
      },
      showError:false,
      isLoading: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    ...mapGetters({errors:"getError"}),
  },
  methods: {
    ...mapActions(["login"]),

    submit: function () {
      this.isLoading = true;
      console.log('this.isLoading',this.isLoading)
      // this.$store.dispatch('login', this.form)
      const form = Object.keys(this.form)
          .map((key, index) => `${key}=${encodeURIComponent(this.form[key])}`)
          .join('&');
      axios.post('/partner/api/domain/partners/login',
          {
            username: this.form.username,
            password: this.form.password
          })
          .then(response => {
           useJwt.setToken(response.data.data.accessToken)
           localStorage.setItem('userInfo', JSON.stringify(response.data.data))
             window.location.reload()
            // this.$router.push('/')
          })
          .catch(err => {
            this.showError = true;
            this.isLoading = false;
          })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.language-switcher {
    position: absolute;
    top: 26px;
    right: 26px;
}
</style>
